import type { ReviewStatus, RoundStatus } from '@mntn-dev/domain-types'
import { Feed, getPersonFullName } from '@mntn-dev/ui-components'
import { ActivityTrans } from '../activity-trans.tsx'
import { Link } from '../index.ts'
import type { ActivityDetailsProps } from './types.ts'

export const PreProductionReviewStatusChanged = ({
  activity: {
    target,
    title,
    person,
    details: {
      activityType,
      data: { reviewStatus, roundStatus, roundNumber, displayName },
    },
  },
  t,
  onNavigate,
  currentUrl,
}: ActivityDetailsProps<'pre_production_review_status_changed'>) => {
  const i18nPropertyKey = <Property extends 'summary' | 'status'>(
    property: Property
  ):
    | `${typeof activityType}.concepting.${Extract<RoundStatus, 'approved' | 'changes_requested'>}.${Property}`
    | `${typeof activityType}.${Exclude<ReviewStatus, 'concepting'>}.${Property}` =>
    reviewStatus === 'concepting'
      ? `${activityType}.concepting.${roundStatus === 'approved' ? 'approved' : 'changes_requested'}.${property}`
      : `${activityType}.${reviewStatus}.${property}`

  return (
    person && (
      <>
        {
          <Feed.Summary>
            <ActivityTrans
              t={t}
              i18nKey={i18nPropertyKey('summary')}
              values={{
                subject: getPersonFullName(person),
                displayName,
                roundNumber,
                reviewStatus: t(i18nPropertyKey('status')),
              }}
            />
          </Feed.Summary>
        }
        <Feed.Annotations>
          <Feed.Tag type={roundStatus === 'approved' ? 'success' : 'error'}>
            {t(i18nPropertyKey('status'))}
          </Feed.Tag>
          {target.fallbackUrn && (
            <Link
              {...{
                target: { name: title, urn: target.fallbackUrn },
                t,
                onNavigate,
                currentUrl,
              }}
            />
          )}
        </Feed.Annotations>
      </>
    )
  )
}
