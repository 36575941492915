import {
  Feed,
  LoadingCenter,
  type TestIds,
  getTestProps,
} from '@mntn-dev/ui-components'
import type { AbstractImage } from '@mntn-dev/ui-utilities'

import type { LanguageId, UserId } from '@mntn-dev/domain-types'
import { useTranslation } from '@mntn-dev/i18n'
import type { ThemeBackground } from '@mntn-dev/ui-theme'
import { Activity } from './activity.tsx'
import type { ActivityModel, ActivityTreatment } from './types.ts'

type ActivityFeedProps = TestIds & {
  activities: ActivityModel[] | undefined
  backgroundColor?: ThemeBackground
  image: AbstractImage
  lng: LanguageId
  onNavigate: (url: string) => void
  currentUrl: string
  treatment: ActivityTreatment
  observerUserId: UserId | undefined
}

export const ActivityFeed = ({
  activities,
  backgroundColor,
  dataTestId,
  dataTrackingId,
  image,
  lng,
  onNavigate,
  currentUrl,
  treatment,
  observerUserId,
}: ActivityFeedProps) => {
  const { t } = useTranslation(lng, 'feed')

  return activities ? (
    <Feed {...getTestProps({ dataTestId, dataTrackingId })}>
      <Feed.ItemList backgroundColor={backgroundColor}>
        {activities?.length ? (
          activities.map((activity) => (
            <Activity
              key={activity.activityId}
              {...{
                activity,
                image,
                lng,
                onNavigate,
                currentUrl,
                treatment,
                observerUserId,
              }}
            />
          ))
        ) : (
          <Feed.Empty>{t('no-activity')}</Feed.Empty>
        )}
      </Feed.ItemList>
    </Feed>
  ) : (
    <LoadingCenter />
  )
}
