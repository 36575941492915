import {
  FileId,
  FileIdSchema,
  type UserDomainQueryModel,
  type UserType,
} from '@mntn-dev/domain-types'
import type { AvatarBorderColor, Person } from '@mntn-dev/ui-components'

import { satisfiesSchema } from '@mntn-dev/utility-types'
import { getFileImageProxyUrl } from '~/utils/client/file-utilities.ts'

export const userDomainQueryModelToActor = (
  model?: UserDomainQueryModel | null
): Person => {
  const fileId = satisfiesSchema(FileIdSchema, model?.avatarStorageKey)
    ? FileId(model?.avatarStorageKey)
    : undefined

  const imageUrl = fileId
    ? getFileImageProxyUrl({
        fileId,
        options: { width: 200, height: 200, gravity: 'custom', crop: 'thumb' },
      })
    : ''

  return {
    firstName: model?.firstName ?? '',
    lastName: model?.lastName ?? '',
    imageUrl,
  }
}

export const userHighlightMap: Record<UserType, AvatarBorderColor> = {
  brand: 'info',
  maker: 'caution',
  internal: 'positive',
}
