import { Feed, getPersonFullName } from '@mntn-dev/ui-components'
import { ActivityTrans } from '../activity-trans.tsx'
import { Link } from '../index.ts'
import type { ActivityDetailsProps } from './types.ts'

export const PreProductionMakerProposalSubmitted = ({
  activity: {
    target,
    title,
    person,
    details: { activityType },
  },
  t,
  onNavigate,
  currentUrl,
}: ActivityDetailsProps<'pre_production_maker_proposal_submitted'>) =>
  person && (
    <>
      <Feed.Summary>
        <ActivityTrans
          t={t}
          i18nKey={`${activityType}.summary`}
          values={{
            subject: getPersonFullName(person),
            object: title,
            status: t(`${activityType}.status`),
          }}
        />
      </Feed.Summary>
      <Feed.Annotations>
        <Feed.Tag type="error">{t(`${activityType}.status`)}</Feed.Tag>
        {target.fallbackUrn && (
          <Link
            {...{
              target: { name: title, urn: target.fallbackUrn },
              t,
              onNavigate,
              currentUrl,
            }}
          />
        )}
      </Feed.Annotations>
    </>
  )
