import { Feed, getPersonFullName } from '@mntn-dev/ui-components'
import { ActivityTrans } from '../activity-trans.tsx'
import { Link } from '../index.ts'
import type { ActivityDetailsProps } from './types.ts'

export const ProjectMatched = ({
  activity: {
    person,
    details: {
      activityType,
      data: { project },
    },
  },
  t,
  onNavigate,
  currentUrl,
}: ActivityDetailsProps<'project_matched'>) =>
  person && (
    <>
      <Feed.Summary>
        <ActivityTrans
          t={t}
          i18nKey={`${activityType}.summary`}
          values={{
            subject: getPersonFullName(person),
            projectName: project.name,
          }}
        />
      </Feed.Summary>
      <Feed.Annotations>
        <Feed.Tag type="success">{t(`${activityType}.status`)}</Feed.Tag>
        <Link
          {...{
            target: project,
            t,
            onNavigate,
            currentUrl,
          }}
        />
      </Feed.Annotations>
    </>
  )
