import Image from 'next/image'
import type React from 'react'

import type { AbstractImageProps } from '@mntn-dev/ui-utilities'
import { forwardRef } from 'react'

type NextImageProps = Partial<React.ComponentProps<typeof Image>>

export const NextImage = (
  nextImageProps?: NextImageProps
): React.ForwardRefExoticComponent<AbstractImageProps> =>
  forwardRef<HTMLImageElement, AbstractImageProps>(
    ({ children, src, alt, width, height, ...props }, ref) => (
      <Image
        {...{
          ...nextImageProps,
          ...props,
          src: src ?? '/',
          alt: alt ?? '',
          width: typeof width === 'number' ? width : undefined,
          height: typeof height === 'number' ? height : undefined,
          ref,
        }}
      >
        {children}
      </Image>
    )
  )
