import { useTranslation } from '@mntn-dev/i18n'
import { SystemUserId } from '@mntn-dev/session-manager'
import { Avatar, Feed } from '@mntn-dev/ui-components'
import type { PropsWithChildren } from 'react'
import { UnhandledUnionError } from '../../utilities/src/errors/unhandled-union-error.ts'
import {
  AgreementTermsAccepted,
  CommentCreated,
  PostProductionReviewStatusChanged,
  PreProductionBrandFeedbackSubmitted,
  PreProductionCompleted,
  PreProductionMakerProposalSubmitted,
  PreProductionReviewStatusChanged,
  ProjectCompleted,
  ProjectCreated,
  ProjectCustomPriceFinalized,
  ProjectFileAdded,
  ProjectInProduction,
  ProjectMatched,
  ProjectOfferExpired,
  ProjectOfferSent,
  ProjectServiceAdded,
  ProjectSubmitted,
  ServiceNoteAdded,
} from './components/activity-details/index.ts'
import type { ActivityProps } from './types.ts'

export const Activity = ({
  activity,
  image,
  lng,
  indicator,
  ...props
}: ActivityProps) => {
  const { activityId, actorId, timestamp, details, person } = activity
  const { treatment } = props
  const { t } = useTranslation(lng, 'activity')
  const isSystemActivity = actorId === SystemUserId

  const ActivityContainer = ({
    activity: { person },
    children,
  }: PropsWithChildren<Pick<ActivityProps, 'activity'>>) => (
    <Feed.Item
      avatar={
        person && !isSystemActivity ? (
          <Avatar.User person={person} />
        ) : (
          <Avatar.Icon name="ExclamationCircleIcon" fill="solid" color="info" />
        )
      }
      key={activityId}
      timestamp={timestamp}
      languageId={lng}
      indicator={indicator}
    >
      {children}
    </Feed.Item>
  )

  switch (details.activityType) {
    case 'agreement_terms_accepted':
      return (
        <ActivityContainer {...{ activity }}>
          <AgreementTermsAccepted
            {...{ activity: { ...activity, details }, t, ...props }}
          />
        </ActivityContainer>
      )

    case 'comment_created':
      return (
        <ActivityContainer {...{ activity }}>
          <CommentCreated
            {...{ activity: { ...activity, details }, t, ...props }}
          />
        </ActivityContainer>
      )

    case 'post_production_review_status_changed':
      return (
        <ActivityContainer {...{ activity }}>
          <PostProductionReviewStatusChanged
            {...{ activity: { ...activity, details }, t, ...props }}
          />
        </ActivityContainer>
      )

    case 'pre_production_brand_feedback_submitted':
      return (
        <ActivityContainer {...{ activity }}>
          <PreProductionBrandFeedbackSubmitted
            {...{ activity: { ...activity, details }, t, ...props }}
          />
        </ActivityContainer>
      )

    case 'pre_production_completed':
      return (
        <ActivityContainer {...{ activity }}>
          <PreProductionCompleted
            {...{ activity: { ...activity, details }, t, ...props }}
          />
        </ActivityContainer>
      )

    case 'pre_production_maker_proposal_submitted':
      return (
        <ActivityContainer {...{ activity }}>
          <PreProductionMakerProposalSubmitted
            {...{ activity: { ...activity, details }, t, ...props }}
          />
        </ActivityContainer>
      )

    case 'pre_production_review_status_changed':
      return (
        <ActivityContainer {...{ activity }}>
          <PreProductionReviewStatusChanged
            {...{ activity: { ...activity, details }, t, ...props }}
          />
        </ActivityContainer>
      )

    case 'project_completed':
      return (
        <ActivityContainer {...{ activity }}>
          <ProjectCompleted
            {...{ activity: { ...activity, details }, t, ...props }}
          />
        </ActivityContainer>
      )

    case 'project_created':
      return (
        <ActivityContainer {...{ activity }}>
          <ProjectCreated
            {...{ activity: { ...activity, details }, t, ...props }}
          />
        </ActivityContainer>
      )

    case 'project_custom_price_finalized':
      return (
        <ActivityContainer {...{ activity }}>
          <ProjectCustomPriceFinalized
            {...{ activity: { ...activity, details }, t, ...props }}
          />
        </ActivityContainer>
      )

    case 'project_file_added':
      return (
        <ActivityContainer {...{ activity }}>
          <ProjectFileAdded
            {...{ activity: { ...activity, details }, t, ...props }}
          />
        </ActivityContainer>
      )

    case 'project_in_production':
      return (
        <ActivityContainer {...{ activity }}>
          <ProjectInProduction
            {...{ activity: { ...activity, details }, t, ...props }}
          />
        </ActivityContainer>
      )

    case 'project_offer_expired':
      return (
        <ActivityContainer {...{ activity }}>
          <ProjectOfferExpired
            {...{ activity: { ...activity, details }, t, ...props }}
          />
        </ActivityContainer>
      )

    case 'project_offer_sent':
      return (
        <ActivityContainer
          {...{
            activity: {
              ...activity,
              person: treatment === 'activity' ? person : undefined,
            },
          }}
        >
          <ProjectOfferSent
            {...{ activity: { ...activity, details }, t, ...props }}
          />
        </ActivityContainer>
      )

    case 'project_matched':
      return (
        <ActivityContainer {...{ activity }}>
          <ProjectMatched
            {...{ activity: { ...activity, details }, t, ...props }}
          />
        </ActivityContainer>
      )

    case 'project_service_added':
      return (
        <ActivityContainer {...{ activity }}>
          <ProjectServiceAdded
            {...{ activity: { ...activity, details }, t, ...props }}
          />
        </ActivityContainer>
      )

    case 'project_submitted':
      return (
        <ActivityContainer {...{ activity }}>
          <ProjectSubmitted
            {...{ activity: { ...activity, details }, t, ...props }}
          />
        </ActivityContainer>
      )

    case 'service_note_added':
      return (
        <ActivityContainer {...{ activity }}>
          <ServiceNoteAdded
            {...{ activity: { ...activity, details }, t, ...props }}
          />
        </ActivityContainer>
      )

    default:
      throw new UnhandledUnionError(details)
  }
}
