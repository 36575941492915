import { Feed, getPersonFullName } from '@mntn-dev/ui-components'
import { Link } from '../index.ts'
import type { ActivityDetailsProps } from './types.ts'
import { ActivityTrans } from '../activity-trans.tsx'

export const CommentCreated = ({
  activity: {
    title,
    person,
    details: { activityType, data },
    target,
  },
  t,
  onNavigate,
  currentUrl,
}: ActivityDetailsProps<'comment_created'>) =>
  person && (
    <>
      <Feed.Summary>
        <ActivityTrans
          t={t}
          i18nKey={`${activityType}.summary`}
          values={{
            subject: getPersonFullName(person),
            object: title,
          }}
        />
      </Feed.Summary>
      <Feed.Quote>{data.text}</Feed.Quote>
      <Feed.Annotations>
        <Link
          {...{
            target: { name: title, urn: target.urn },
            t,
            onNavigate,
            currentUrl,
          }}
        />
      </Feed.Annotations>
    </>
  )
