import { Feed, getPersonFullName } from '@mntn-dev/ui-components'
import type { ActivityTreatment } from '../../types.ts'
import { ActivityTrans } from '../activity-trans.tsx'
import { Link } from '../index.ts'
import type { ActivityDetailsProps } from './types.ts'

export const ProjectOfferSent = ({
  activity: {
    person,
    details: {
      activityType,
      data: { project, offers },
    },
  },
  t,
  onNavigate,
  currentUrl,
  observerUserId,
  treatment,
}: ActivityDetailsProps<'project_offer_sent'>) => {
  const treatmentMap: Record<ActivityTreatment, () => JSX.Element | undefined> =
    {
      activity: () =>
        person && (
          <>
            <Feed.Summary>
              <ActivityTrans
                t={t}
                i18nKey={`${activityType}.activity.summary`}
                values={{
                  subject: getPersonFullName(person),
                  offerName: project.name,
                  count: offers.length,
                }}
              />
            </Feed.Summary>
            <Feed.Annotations>
              <Feed.Tag type="notice">
                {t(`${activityType}.activity.tag`)}
              </Feed.Tag>
              <Link {...{ target: project, t, onNavigate, currentUrl }} />
            </Feed.Annotations>
          </>
        ),
      notification: () => {
        const offer = offers.find(
          (offer) => offer.acceptorId === observerUserId
        )

        return (
          offer && (
            <>
              <Feed.Summary>
                <ActivityTrans
                  t={t}
                  i18nKey={`${activityType}.notification.summary`}
                  values={{
                    offerName: project.name,
                  }}
                />
              </Feed.Summary>
              <Feed.Annotations>
                <Feed.Tag type="notice">
                  {t(`${activityType}.notification.tag`)}
                </Feed.Tag>
                <Link
                  {...{
                    target: { name: project.name, urn: offer.urn },
                    t,
                    onNavigate,
                    currentUrl,
                  }}
                />
              </Feed.Annotations>
            </>
          )
        )
      },
    }

  return treatmentMap[treatment]()
}
