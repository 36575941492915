import { Feed, type TestIds } from '@mntn-dev/ui-components'

import { urnUrlBuilder } from '@mntn-dev/app-routing'
import type { ActivityDataTarget } from '@mntn-dev/domain-types'
import type { TFunction } from 'i18next'

type LinkProps = TestIds & {
  target: ActivityDataTarget
  t: TFunction<'activity'>
  onNavigate: (url: string) => void
  currentUrl: string
}

export const Link = ({ target, t, onNavigate, currentUrl }: LinkProps) => {
  const targetUrl = urnUrlBuilder(target.urn)

  const handleClick = (url: string) => () => onNavigate(url)

  return (
    targetUrl &&
    // Don't show a Link if we're currently on it's URL
    targetUrl.url !== currentUrl && (
      <Feed.LinkButton onClick={handleClick(targetUrl.url)}>
        {t(`link.${targetUrl.idTag}`)}
      </Feed.LinkButton>
    )
  )
}
