import { Trans } from '@mntn-dev/i18n'
import { Text } from '@mntn-dev/ui-components'
import type React from 'react'

type ActivityTransProps = React.ComponentProps<typeof Trans>

const ActivityTrans = (props: ActivityTransProps) => (
  <Trans
    {...props}
    components={{
      primary: <Text textColor="primary" />,
      secondary: <Text textColor="secondary" />,
    }}
  />
)

export { ActivityTrans }
